<template>
  <div class="about">

  </div>
</template>

<script>
import 'vant/es/toast/style'

export default {
  name: 'Home',

  data () {
    return {
      type: 'mx',
      menuItems: [
        {
          key: 'entry',
          label: '项目入口',
          icon: '',
          type: 'base',
          children: []
        },
        {
          key: 'sub2',
          label: 'subnav 2',
          icon: '',
          type: 'base'
        },
        {
          key: 'sub3',
          label: 'subnav 3',
          icon: '',
          type: 'base',
          children: [
            {
              key: 'sub3-1',
              label: 'option9',
              type: 'base'
            }
          ]
        }
      ]
    }
  },

  mounted () {
    const data = this.filterRoutes(this.menuItems)
    console.log(data)
  },

  methods: {
    filterRoutes (routes) {
      return routes.filter(route => {
        let url = ''
        if (route.url) {
          if (route.key === this.type) {
            url = route.url
          }
        }
        if (route.children && route.children.length) {
          route.children = this.filterRoutes(route.children)
        }
        console.log(url)
        return url
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
